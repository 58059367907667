import React, { useEffect, useLayoutEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";

// Context
import { useAuth } from "./context/useAuth";

// Pages
import ContentList from "./pages/ContentList";
import ContentEdit from "./pages/ContentEdit";
import ContentTypeList from "./pages/ContentTypeList";
import ContentTypeEdit from "./pages/ContentTypeEdit";
import ResetPassword from "./pages/ResetPassword";
import SetPassword from "./pages/SetPassword";
import Login from "./pages/Login";
import Profile from "./pages/Profile";
import Privacy from "./pages/Privacy";
import CalendarAI from "./pages/CalendarAI";
import Media from "./pages/Media";

export default function RoutesApp() {
  let auth = useAuth();
  const location = useLocation();

  // Scroll to top if path changes
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  useEffect(() => {
    auth.checkAuth();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Routes>
      {auth.user ? (
        <>
          <Route path="/" element={<ContentList />} />
          <Route path="/contents/:id" element={<ContentEdit />} />
          <Route path="/contentTypes" element={<ContentTypeList />} />
          <Route path="/contentTypes/:id" element={<ContentTypeEdit />} />
          <Route path="/calendarAI" element={<CalendarAI />} />
          <Route path="/media" element={<Media />} />
          <Route path="/profile" element={<Profile />} />
        </>
      ) : (
        <>
          <Route path="/" element={<Login />} />
        </>
      )}
      <Route path="/privacy" element={<Privacy />} />
      <Route path="/setPassword/:id/:token" element={<SetPassword />} />
      <Route path="/resetPassword" element={<ResetPassword />} />
    </Routes>
  );
}
