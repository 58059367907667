import { create } from "apisauce";
import packageJson from "../../package.json";

export const createApi = () => {
  const api = create({
    baseURL: process.env.REACT_APP_URL_API,
    headers: {
      "Cache-Control": "no-cache",
      "app-version": packageJson.version,
    },
    timeout: 600000,
  });

  const setToken = (token) => {
    if (token) {
      const headerPrefix = "Bearer ";
      const header = "Authorization";
      api.addRequestTransform((request) => {
        request.headers[header] = `${headerPrefix}${token}`;
        return request;
      });
    }
  };

  const login = (email, password) => {
    return api.post(`/api/users/login`, { email, password });
  };

  const resetPassword = (userId, token, password) => {
    return api.post(`/api/users/resetPassword`, { userId, token, password });
  };

  const resetPasswordRequest = (email) => {
    return api.post(`/api/users/resetPasswordRequest`, { email });
  };

  const getUser = () => {
    return api.get(`/api/users/getUser`, {});
  };

  const saveUser = (user) => {
    return api.post(`/api/users`, user);
  };

  // Contents

  const getContent = (id) => {
    return api.get(`/api/contents/${id}`);
  };

  const getContents = () => {
    return api.get(`/api/contents`);
  };

  const getContentType = (id) => {
    return api.get(`/api/contentTypes/${id}`);
  };

  const saveContent = (item) => {
    return api.post(`/api/contents/${item._id || ""}`, item);
  };

  const removeContent = (id) => {
    return api.delete(`/api/contents/${id}`);
  };

  // Content type
  const getContentTypes = () => {
    return api.get(`/api/contentTypes`);
  };

  const saveContentType = (item) => {
    return api.post(`/api/contentTypes/${item._id || ""}`, item);
  };

  const removeContentType = (id) => {
    return api.delete(`/api/contentTypes/${id}`);
  };

  const getUploadMedia = () => {
    return api.get(`/api/contentTypes/uploadMedia`);
  };

  const uploadMedia = (file) => {
    const data = new FormData();
    data.append("file", file);
    return api.post(`/api/contentTypes/uploadMedia`, data);
  };

  // Contents
  const generateContent = (idContent) => {
    return api.post(`/api/contents/${idContent}/generateImage`);
  };

  const generateMetadata = (idContent) => {
    return api.post(`/api/contents/${idContent}/generateMetadata`);
  };

  const publishContent = (idContent) => {
    return api.post(`/api/contents/${idContent}/publish`);
  };

  const createContentWizard = (content) => {
    return api.post(`/api/contents/createContentWizard`, content);
  };

  const contentUploadMedia = (file, contentId) => {
    const data = new FormData();
    data.append("file", file);
    return api.post(`/api/contents/${contentId}/uploadMedia`, data);
  };

  const generateCalendar = (params) => {
    return api.post(`/api/contents/generateCalendar`, params);
  };

  const saveCalendar = (contents) => {
    return api.post(`/api/contents/saveCalendar`, contents);
  };

  // Predictions
  const getPredictions = (idContent) => {
    return api.get(`/api/predictions/content/${idContent}`);
  };

  const removePrediction = (idContent, i) => {
    return api.delete(`/api/predictions/content/${idContent}/${i}`);
  };

  const getMedia = () => {
    return api.get(`/api/predictions/media`);
  };

  const addPreferred = (predictionId, output) => {
    return api.post(`/api/predictions/${predictionId}/addPreferred`, {
      output,
    });
  };

  const removePreferred = (predictionId, output) => {
    return api.post(`/api/predictions/${predictionId}/removePreferred`, {
      output,
    });
  };

  // Instagram
  const instagramLogin = (response) => {
    return api.post(`/api/instagram/login`, response);
  };
  const enableWebhook = () => {
    return api.post(`/api/instagram/enableWebhook`);
  };
  const getConversations = () => {
    return api.get(`/api/instagram/conversations`);
  };
  const setConversationIgnore = (convId, ignore) => {
    return api.post(`/api/instagram/conversations/${convId}`, { ignore });
  };

  const searchPage = (search) => {
    return api.post(`/api/instagram/searchPage`, { search });
  };

  const getPosts = () => {
    return api.post(`/api/instagram/getPosts`, {});
  };

  // return functions for interface
  return {
    // Security
    setToken,
    login,
    resetPassword,
    resetPasswordRequest,
    getUser,
    saveUser,

    // Contents
    getContents,
    getContent,
    saveContent,
    removeContent,
    createContentWizard,
    generateContent,
    generateMetadata,
    publishContent,
    contentUploadMedia,
    generateCalendar,
    saveCalendar,

    // Content type
    getContentTypes,
    getContentType,
    saveContentType,
    removeContentType,
    uploadMedia,
    getUploadMedia,

    // Predictions
    getPredictions,
    removePrediction,
    getMedia,
    addPreferred,
    removePreferred,

    // Instagram
    instagramLogin,
    enableWebhook,
    getConversations,
    setConversationIgnore,
    searchPage,
    getPosts,
  };
};

// Creates a singleton
export default createApi();
