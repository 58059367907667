import { Link } from "react-router-dom";
import hamburger from "../images/hamburger.svg";
import logo from "../images/logo.svg";
import { useState } from "react";

// Context
import { useAuth } from "../context/useAuth";

export default function Navbar() {
  const [showMenu, setShowMenu] = useState(false);
  const auth = useAuth();

  const triggerMenu = () => {
    setShowMenu(!showMenu);
  };

  return (
    <div className={"navbar navbar-admin" + (showMenu ? " open" : "")}>
      <div className="navbar-left">
        <Link to="/">
          <img className="navbar-logo" src={logo} alt="Logo"></img>
          <span className="navbar-logo-text">Insta AI</span>
        </Link>
      </div>

      <div className="navbar-center"></div>

      <div className="navbar-burger-content">
        <img onClick={() => triggerMenu()} className="navbar-burger" src={hamburger} alt="hamburger"></img>
      </div>

      <div className={"navbar-right" + (showMenu ? " open" : "")}>
        <div className="navbar-btn">
          <Link to="/">Contents</Link>
        </div>

        <div className="navbar-btn">
          <Link to="/contentTypes">Types</Link>
        </div>

        <div className="navbar-btn">
          <Link to="/media">Media</Link>
        </div>

        <div className="navbar-btn">
          <Link to="/profile">Profile</Link>
        </div>

        <div className="navbar-btn">
          {/* eslint-disable-next-line */}
          <a onClick={auth.logout} style={{ curosor: "pointer" }}>
            Logout
          </a>
        </div>
      </div>
    </div>
  );
}
